<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg" >
        <h2 class="uk-text-center page-title uk-padding-small">
          Contact<br>
          お問い合わせ
        </h2>
        
        <div v-if="responseMassage !== ''" style= "padding:5vh;box-sizing: border-box;font-size:1.5rem;">
          {{responseMassage}}
        </div>
        <form id="inquriy" class="uk-form-horizontal form-cls" v-if="responseMassage === ''" >
          <div class="uk-margin input-cls" v-for="(item, i) in items" :key="i">
              <label class="uk-form-label" for="form-horizontal-text">{{item.required + $_chlang(item.title)}}</label>
              <div class="uk-form-controls ">
                  <input v-if="item.type === 'text'" class="uk-input" :type="item.type"  :id="item.name" :name="item.name" @input="setText(item.required, $event)" >
                  <textarea v-if="item.type === 'textarea'" class="uk-textarea" rows="5" :id="item.name" :name="item.name" @input="setText(item.required, $event)" ></textarea>
              </div>
          </div>
          <div class="uk-margin" style="text-align:center;">
            <a :class="{'is-passive': isPassive}" class="btn" @click="inqriyPost">
              <span>＞＞＞{{$_chlang({ja:'送信する',en:'send'})}}</span>
            </a>
          </div>

        </form>
      </div>
    </div>
  </div>
  
</template>


<script>
export default {
  data: () => ({
    chk:[],
    isPassive: true ,
    items : [],
    responseMassage : ''
  }),
  methods : {
    setText(required,e) {
      if(required === '*' ){
        if(e.target.value.length > 0) {
          this.chk.splice(this.chk.indexOf(e.target.name), 1)
        }else{
          this.chk.push(e.target.name);
        }
      }

    },
    inqriyPost: function(){
      let formData = new FormData(document.getElementById('inquriy'));

      const formDataJson = {};
      for (const [key, value] of formData.entries()) {
        formDataJson[key] = value;
      }
      this.$_apiPost( JSON.stringify(formDataJson),'/inquiry/wrd',(response) => { 
        for(let item of this.items){
              document.getElementById(item.name).value = "";
            }
            this.responseMassage = response.data;
       });    
    }
  },
  mounted: function() {
    let requiredItems = this.items.filter(item => item.required === '*');
    for(const item of requiredItems) this.chk.push(item.name);
  },
  watch: {
    chk : function () {
      this.isPassive = (this.chk.length != 0);
    }
  },
  created() {
    this.items = this.commonSetting.contactItem
    this.responseMassage = '';
  }

}
</script>



<style scoped lang="scss">

.parallax-bg {
  background: linear-gradient(rgba(255, 255, 255, 0.5),  rgba(255, 255, 255, 0.5)), url(~@/assets/img/image/page-contact.jpg);
}

h2{
  color: #fff;
  background-image:url(~@/assets/img/image/page-contact-top.jpg);
  background-position: center;
  background-size: cover;
  padding: 5%;
}
.form-cls{
  margin: 5vh 10vw;
}
.input-cls{
  margin: 2vh 5vw;

  .uk-form-label{
    font-weight: 400;
  }
}


.is-passive{
  pointer-events:none !important;
  border: 2px solid rgb(152, 152, 152);/* ボーダーの色と太さ */
  background-color: rgba(255, 255, 255, 0.3);
    span{
      color:rgb(152, 152, 152)!important;
    }
}

</style>
